import {
  AuthConfig,
  AuthProvider as AuthProviderConteg,
  User,
} from '@conteg/auth';
import { AUTH_CLIENT_ID } from 'config';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { ReactNode } from 'react';
import env from 'utils/env/env';

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const onSignIn = (user: User | void): void => {
    // Store Username
    if (user?.access_token) {
      const jwtPayload = jwtDecode<JwtPayload & { UserName: string }>(
        user?.access_token
      );
      window.localStorage.setItem('user', JSON.stringify(jwtPayload.UserName));
    }
  };

  const authConfig: AuthConfig = {
    scope: 'Api.access',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    subjectId: env.VITE_AUTH_SUBJECT_ID ?? '',
    applicationInstanceId: env.VITE_AUTH_APP_INSTANCE_ID ?? '',
    authority: env.VITE_API_BASE_URL ?? '',
    clientId: AUTH_CLIENT_ID,
    issuer: env.VITE_AUTH_META_ISSUER ?? '',
    jwksUri: `${env.VITE_API_BASE_URL}/.well-known/openid-configuration/jwks`,
    authorizationEndpoint: `${env.VITE_API_BASE_URL}/connect/authorize`,
    tokenEndpoint: `${env.VITE_API_BASE_URL}/connect/token`,
    userInfoEndpoint: `${env.VITE_API_BASE_URL}/connect/userinfo`,
    endSessionEndpoint: `${env.VITE_API_BASE_URL}/connect/endsession`,
    onSignIn,
  };

  return (
    <AuthProviderConteg config={authConfig}>{children}</AuthProviderConteg>
  );
};

export default AuthProvider;
